<template>
  <section class="construction-types">
    <TextInput :formValues="sectorNameTitle" @input="update($event, 'name')" />
    <article class="construction-types__types-switcher">
      <form>
          <radio-button
            v-for="type in constructionTypes"
            :key="type.id"
            :selected="constructionType"
            :radioGroup="type"
            @input="update($event, 'constructionType', 'type')"
          />
      </form>
    </article>
    <article v-if="!sector.constructionType.isCombinedLocation" class="construction-types__attributes">
      <template v-for="dropdown in dropdowns">
        <OptionDropdown
          v-if="dropdown.viewCondition"
          :updateKey="`${constructionType}-${sector.id}`"
          :dropdown="dropdown"
          :key="dropdown.id"
          @update="update($event, 'constructionType', `${dropdown.id}`)"
        />
      </template>
    </article>
    <AdditionalParams v-if="isFloor" :sector="sector" @update="addAdditionalFloorParameters($event)">
      <template v-for="dropdown in additionalFloorParams">
        <OptionDropdown
          v-if="dropdown.viewCondition"
          :updateKey="`${constructionType}-${sector.id}`"
          :dropdown="dropdown"
          :key="dropdown.id"
          @update="update($event, 'constructionType', `${dropdown.id}`)"
        />
      </template>
    </AdditionalParams>
  </section>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'
import TextInput from '@/components/dump/TextInput'
import RadioButton from '@/components/dump/RadioButton'
import OptionDropdown from '@/components/dump/OptionDropdown'
import { uuid } from 'vue-uuid'
import { mapActions, mapGetters } from 'vuex'
import { ConstructionTypes } from '@/utils/types'
import AdditionalParams from '@/components/smart/AdditionalParams.vue'
import DropdownConfigsMixin from '@/components/mixin/DropdownConfigsMixin.vue'

export default {
  name: 'ConstructionTypes',
  mixins: [IconMixin, DropdownConfigsMixin],
  props: ['sector'],
  components: { RadioButton, TextInput, OptionDropdown, AdditionalParams },
  data() {
    return {
      emptyOptions: [ { id: 0, value: '' } ]
    }
  },
  watch: {
    isRoomAlgorithm() {
      if (this.isRoomAlgorithm) {
        this.getSystemForRooms(this.sectorIndex)
      }
    }
  },
  computed: {
    ...mapGetters({
      getConstructionTypes: 'getConstructionTypes',
      getRooms: 'getRooms'
    }),
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    sectorNameTitle() {
      return {
        label: this.$t('message.constructionTypes.input.label'),
        sectorName: this.sector.name ?? '',
        id: uuid.v1()
      }
    },
    constructionType() {
      return this.sector.constructionType.type
    },
    currentConstructionTypeId() {
      return this.getConstructionTypes.find((type) => type.constructionType === this.constructionType)?.constructionId
    },
    buildingType() {
      return this.sector.constructionType.buildingType
    },
    location() {
      return this.sector.constructionType.location
    },
    constructionTypes() {
      if (this.getConstructionTypes.length === 0) return this.getConstructionTypes

      const types = this.getConstructionTypes.map((type, index) => {
        return {
          id: index,
          constructionId: type.constructionId,
          img: this.images[type.constructionId],
          value: type.constructionType,
          name: this.$t('message.constructionTypes.types.title')
        }
      })

      return types[0].value === this.$t('message.constructionTypes.types.walls')
        ? types
        : types.reverse()
    },
    buildingTypes() {
      if (this.constructionType && this.getConstructionTypes.length) {
        const buildings = this.getConstructionTypes
          ?.find((type) => type.constructionType === this.constructionType)
          ?.buildings
        return buildings ? this.getOptions(buildings, true) : this.emptyOptions
      }

      return this.emptyOptions
    },
    locations() {
      if (!this.buildingType) {
        return this.emptyOptions
      }

      const locations = this.buildingTypes?.find((location) => location.name === this.buildingType)
        ?.locations

      return this.buildingType && locations ? this.getOptions(locations, true) : this.emptyOptions
    },
    ratings() {
      if (!this.buildingType) {
        return this.emptyOptions
      }

      const ratings = this.buildingTypes?.find((buildingType) => buildingType.name === this.buildingType)?.ratings

      return ratings ? this.getOptions(ratings, false) : this.emptyOptions
    },
    topRooms() {
      return this.constructionType && this.getRooms && this.getRooms[this.currentConstructionTypeId]
        ? this.getOptions(this.getRooms[this.currentConstructionTypeId], true)
        : this.emptyOptions
    },
    bottomRooms() {
      const locations = this.topRooms?.find((item) => item.value === this.buildingType)?.locations

      return this.buildingType && locations
        ? this.getOptions(locations, true)
        : this.emptyOptions
    },
    isHotel() {
      if (!this.currentConstructionTypeId) return false

      const originalBuildingType = this.getConstructionTypes
        ?.find(item => item.constructionId === this.currentConstructionTypeId)
        ?.buildings.find(item => item.name === this.sector.constructionType.buildingType)

      return originalBuildingType && originalBuildingType.ratings
    },
    isFloor() {
      return this.currentConstructionTypeId === ConstructionTypes.floor
    },
    isRoomAlgorithm() {
      return this.sector.constructionType.isCombinedLocation && this.sector.constructionType.location?.id
    },
    images() {
      return {
        floor: require('@/assets/img/floors.png'),
        wall: require('@/assets/img/walls.png')
      }
    }
  },
  methods: {
    ...mapActions({
      getSystemForRooms: 'getSystemForRooms'
    }),
    update(evt, property, subproperty) {
      this.sector.isCalculated || this.sector.system
        ? this.editSector(evt, property, subproperty)
        : this.setFirstStepValues(evt, property, subproperty)
    },
    resetConstructionTypesValues(property, evt) {
      ['type', 'isCombinedLocation', ...this.dropdowns.map(item => item.id)].forEach((subproperty) => {
        this.setConstructionTypesValues(property, subproperty, this.getInitialValue(subproperty, evt))
      })
      this.setConstructionTypesValues('roomSystems', null, null)
      this.$emit('checkStepValidation')
    },
    getInitialValue(subproperty, evt) {
      switch (subproperty) {
        case 'type':
          return evt.newValue
        case 'isCombinedLocation':
          return false
        default:
          return null
      }
    },
    setConstructionTypesValues(property, subproperty, value) {
      this.$store.commit('UPDATE_SECTOR', {
        index: this.sectorIndex,
        property,
        subproperty,
        value
      })
    },
    editSector(evt, property, subproperty) {
      this.setFirstStepValues(evt, property, subproperty)
      this.$emit('checkStepValidation')
      this.setConstructionTypesValues('isCalculated', null, false)
    },
    setFirstStepValues(evt, property, subproperty) {
      subproperty === 'type'
        ? this.resetConstructionTypesValues(property, evt)
        : this.setConstructionTypesValues(property, subproperty, evt.newValue)

      if (this.sector.constructionType.isCombinedLocation && subproperty === 'buildingType') {
        this.setConstructionTypesValues('constructionType', 'location', null)
      }
    },
    addAdditionalFloorParameters(evt) {
      if (!evt) {
        this.setConstructionTypesValues('roomSystems', null, null)
      }
      ['buildingType', 'location', 'rating'].forEach((subproperty) => {
        this.setConstructionTypesValues('constructionType', subproperty, null)
      })
      this.update({ newValue: evt }, 'constructionType', 'isCombinedLocation')
    }
  }
}
</script>

<style scoped lang="sass">
.construction-types
  width: 100%
  &__types-switcher
    form
      display: grid
      grid-template-rows: auto
      +media((grid-template-columns: (0: 1fr, 768: repeat(2, 1fr))))
      +media((grid-gap: (0: rem(10), 768: rem(20))))
      margin-bottom: rem(30)
  &__attributes
    display: grid
    grid-template-columns: 2fr 1fr
    +media((grid-template-areas: (0: 'buildingType buildingType' 'rating rating' 'location location', 992: 'buildingType buildingType rating' 'location location location')))
</style>
